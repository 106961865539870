import React from 'react'

import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
const department = [
    {
      title: 'Diagnostic testing',
      subTitle:
        'Blood tests, imaging studies, and other tests to diagnose health conditions',
      iconUrl: '/images/icons/calendar_white.svg',
      href: '/departments/department-details',
    },
    {
      title: 'Rehabilitation services',
      subTitle:
        'Physical therapy, occupational therapy, and other services to help patients recover from injuries',
      iconUrl: '/images/icons/calendar_white.svg',
      href: '/departments/department-details',
    },
    {
      title: 'Preventive care',
      subTitle:
        'Annual checkups, immunizations, and health screenings care preventive',
      iconUrl: '/images/icons/calendar_white.svg',
      href: '/departments/department-details',
    },
    {
      title: 'Treatment for acute and chronic conditions',
      subTitle:
        'Medication management, disease management, and other treatments to improve health outcomes',
      iconUrl: '/images/icons/calendar_white.svg',
      href: '/departments/department-details',
    },
    {
      title: 'Mental health services',
      subTitle:
        'Counseling, therapy, and other services to help patients manage mental health conditions',
      iconUrl: '/images/icons/calendar_white.svg',
      href: '/departments/department-details',
    },
  ];
const Policy = () => {
    pageTitle('Privacy-Policy | ');
  return (
    <div>
        <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to <br />ProHealth Medical & Healthcare Center"
        subTitle="Your Partner in Health and Wellness"
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <div className='container'>
        <div className='col-md-12'>

       <h4> Introduction</h4>
       <p>Welcome to healthmatrix. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.</p>
       <h4>Data We Collect About You</h4>
       <p>We may collect, use, store, and transfer different kinds of personal data about you which we have grouped together as follows:
        </p>
        <ul>
            <li><span className='fw-bold '>Identity Data</span> includes first name, last name, username or similar identifier.</li>
            <li><span className='fw-bold '>Contact Data</span> includes billing address, delivery address, email address, and telephone numbers.
            </li>
            <li><span className='fw-bold '>Financial Data</span> includes bank account and payment card details.</li>
            <li><span className='fw-bold '>Transaction Data</span> includes details about payments to and from you and other details of services you have purchased from us.
            </li>
            <li><span className='fw-bold '>Technical Data</span> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.
            </li>
        </ul>
       <h4> How We Use Your Personal Data</h4>
       <p> We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
       </p>
       <ul>
            <li>To register you as a new customer.</li>
            <li>To process and deliver your services including managing payments and collecting money owed to us.
            </li>
            <li>To manage our relationship with you which will include notifying you about changes to our terms or privacy policy.</li>
        </ul>
       <h4> Disclosure of Your Personal Data</h4>
       <p>We may have to share your personal data with external third parties such as service providers, professional advisers, regulators, and other authorities.</p>
       <h4>Data Security</h4>
       <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed.</p>
       <h4>Data Retention</h4>
       <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </p>
       <h4>Your Legal Rights</h4>
       <p> Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, restriction, transfer, to object to processing, to portability of data, and (where the lawful ground of processing is consent) to withdraw consent.
      </p>
      <h4>Contact Us</h4>
      <p>If you have any questions about this privacy policy or our privacy practices, please contact us at support@healthmatrix.co.in.
      </p>
      </div>
      </div>
      </Section>
      </div>


  )
}

export default Policy;
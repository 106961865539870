import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
// import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';
const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Compassion',
    subTitle:
      'We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease and every one.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'We are committed to providing excellent medical care and services to our patients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Integrity',
    subTitle: `We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Respect',
    subTitle:
      'We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Teamwork',
    subTitle:
      'We believe in working collaboratively with our team membersvand other healthcare professionals to provide comprehensive and effective care to our patients.',
  },
];
const brandData = [
  { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_5.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_6.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_7.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_8.png', imgAlt: 'Brand' },
];
const faqData = [
  {
    title: 'What is healthmatrix.co.in?',
    content:
      ' healthmatrix.co.in is a comprehensive healthcare platform offering subscription-based services for OPD, telemedicine consultations, doctor consultancy, diagnosis bookings, and hospital recommendations.',
      
  },
  {
    title: 'How does the subscription service work?',
    content:
      'Our subscription service allows users to access a range of healthcare services for a fixed monthly fee. Users can subscribe to the service and enjoy benefits such as unlimited OPD consultations, telemedicine appointments, and more.',
  },
  {
    title: 'What are the benefits of subscribing?',
    content:
      'Subscribers enjoy convenient access to healthcare services from the comfort of their homes, cost savings compared to traditional healthcare visits, priority scheduling for appointments, and personalized healthcare recommendations.',
  },
  {
    title: 'How do I book an OPD consultation?',
    content:
      ' Booking an OPD consultation is simple. Just log in to your account, choose your preferred doctor, select a suitable time slot, and confirm your appointment.',
  },
  {
    title: 'Can I choose my preferred doctor for an OPD consultation?',
    content:
      ' Yes, you can browse through our list of experienced doctors, view their profiles, and select the one that best suits your needs.',
  },
  {
    title: 'What is telemedicine, and how does it work?',
    content:
      ' Telemedicine allows patients to consult with healthcare professionals remotely via video or phone calls. Users can schedule telemedicine appointments through our platform and connect with doctors from anywhere.',
  }, 
  {
    title: 'Is telemedicine secure and confidential?',
    content:
      '  Absolutely. We prioritize the security and confidentiality of patient information. Our telemedicine platform complies with all relevant data protection regulations to ensure privacy and security.',
  },
  {
    title: 'What types of healthcare professionals are available for consultancy?',
    content:
      'Our platform offers consultations with a diverse range of healthcare professionals, including general physicians, specialists, therapists, and more.',
  },
  {
    title: 'Can I ask multiple questions during a doctor consultancy session?',
    content:
      ' Yes, you can discuss multiple concerns during your consultancy session. Our doctors are here to address all your healthcare needs comprehensively.',
  },
  {
    title: 'How do I book a diagnostic test?',
    content:
      ' Booking a diagnostic test is easy. Simply log in to your account, select the test you need, choose a certified lab or diagnostic center near you, and schedule your appointment.',
  },
  {
    title: 'How soon can I expect my test results?',
    content:
      ' The turnaround time for test results varies depending on the type of test. However, we strive to deliver results as quickly as possible and keep you informed throughout the process.',
  },
  {
    title: 'Can you recommend hospitals and healthcare facilities?',
    content:
      'Yes, we provide recommendations for hospitals and healthcare facilities based on your location, medical needs, and preferences. Our goal is to help you find the best possible care options.',
  },
  {
    title: 'How can I manage my account?',
    content:
      ' You can manage your account settings, appointments, subscriptions, and payment details through our user-friendly dashboard.',
  },
  {
    title: 'What payment methods do you accept?',
    content:
      ' We accept payments through various secure methods, including credit/debit cards, net banking, and digital wallets.',
  },
  {
    title: 'What should I do if I encounter technical issues on the website?',
    content:
      ' If you experience any technical difficulties or have questions about using our platform, please reach out to our customer support team. We are here to assist you 24/7.',
  },
  {
    title: 'How do you protect my personal and medical information?',
    content:
      ' We take data privacy and security seriously. Our platform employs robust encryption protocols and adheres to strict privacy regulations to safeguard your personal and medical information.',
  },
];
// const blogData = [
//   {
//     title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
//     thumbUrl: 'images/home_1/post_1.jpg',
//     date: 'May 1, 2023',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//     socialShare: true,
//   },
//   {
//     title: 'Healthy Eating on a Budget: Tips and Strategies',
//     thumbUrl: 'images/home_1/post_2.jpg',
//     date: 'May 4, 2023',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//     socialShare: true,
//   },
//   {
//     title: 'The Importance of Regular Cancer Screenings and Early Detection',
//     thumbUrl: 'images/home_1/post_3.jpg',
//     date: 'May 1, 2023',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//     socialShare: true,
//   },
// ];

const awardData = [
  {
    title: 'Malcolm Baldrige National Quality Award',
    subTitle:
      'This award recognizes healthcare organizations that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'HIMSS Davies Award',
    subTitle:
      'This award recognizes healthcare organizations that have used health information technology to improve patient outcomes and reduce costs.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Healthgrades National’s Best Hospital',
    subTitle:
      'This recognition is given to hospitals that have achieved high ratings for clinical quality and patient safety across multiple specialties and procedures.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Joint Commission Gold Seal of Approval',
    subTitle:
      'This recognition is given to hospitals that have met rigorous standards for patient safety and quality of care.',
    iconUrl: 'images/icons/award.svg',
  },
];
const departmentData = [
  {
    title: 'Emergency Department',
    iconUrl: 'images/home_1/department_icon_1.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Pediatric Departement',
    iconUrl: 'images/home_1/department_icon_2.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Gynecology Department',
    iconUrl: 'images/home_1/department_icon_3.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Cardiology Department',
    iconUrl: 'images/home_1/department_icon_4.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Neurology Department',
    iconUrl: 'images/home_1/department_icon_5.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Psychiatry Department',
    iconUrl: 'images/home_1/department_icon_6.svg',
    href: '/departments/department-details',
  },
];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      <Hero
        title="Your Partner in Health and Wellness"
        subTitle="We are committed to providing you with the best medical and healthcare services to help you live healthier and happier."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: 'Hotline',
            subTitle: '123-456-7890',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'Ambulance',
            subTitle: '876-256-876',
            iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: 'Location',
            subTitle: 'New York, US',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Book Now"
        btnUrl="/appointments"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="HEALTH MATRIX"
          featureList={[
            {
              featureListTitle:
                'ProHealth is a team of experienced medical professionals',
              featureListSubTitle:
                'Dedicated to providing top-quality healthcare services. We believe in a holistic approach to healthcare that focuses on treating the whole person, not just the illness or symptoms.',
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Departments" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section>
      {/* End Brand Section */}
    </>
  );
}

import React from 'react';

import { Icon } from '@iconify/react';

export default function Text({

  featureList,

}) {
  return (
    <div className="cs_pricing_card cs_style_1 cs_radius_20 overflow-hidden">
      
   
        <ul className="cs_pricing_card_feature cs_fs_20 cs_heading_color">
          {featureList?.map((item, index) => (
            <li key={index}>
              <i>
                <Icon icon="fa6-solid:circle-check" />
              </i>
              {item}
            </li>
          ))}
        </ul>
    
     
    </div>
  );
}

import React from 'react'
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3'
import { pageTitle } from '../../helpers/PageTitle'
import Section from '../Section'

const DepartmentData =  [
  {
    title: 'Diagnostic testing',
    subTitle:
      'Blood tests, imaging studies, and other tests to diagnose health conditions',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Rehabilitation services',
    subTitle:
      'Physical therapy, occupational therapy, and other services to help patients recover from injuries',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Preventive care',
    subTitle:
      'Annual checkups, immunizations, and health screenings care preventive',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Treatment for acute and chronic conditions',
    subTitle:
      'Medication management, disease management, and other treatments to improve health outcomes',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Mental health services',
    subTitle:
      'Counseling, therapy, and other services to help patients manage mental health conditions',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
]
  const Terms = () => {
    pageTitle('Terms & Conditions | ');
  return (
    <div>    <BannerSectionStyle3
    bgUrl="/images/about/banner_bg.svg"
    imgUrl="/images/about/banner_img.png"
    title="Welcome to <br />ProHealth Medical & Healthcare Center"
    subTitle="Your Partner in Health and Wellness"
  />
    <Section topMd={200} topLg={150} topXl={110}>
    <div className='container'>
    <div className='col-md-12'>
      <h4>Introduction</h4>
        <p> These terms and conditions outline the rules and regulations for the use of healthmatrix’s Website, located at healthmatrix.co.in. By accessing this website, we assume you accept these terms and conditions. Do not continue to use healthmatrix if you do not agree to all of the terms and conditions stated on this page.
        </p>
        <h4>Services Offered</h4>
        <p>healthmatrix provides subscription-based healthcare services including OPD, telemedicine, doctor consultancy, diagnostic booking, and hospital recommendation services.
        </p>
        <h4>Subscription</h4>
        <p>A subscription is required to access certain services on our website. Subscriptions may be offered on a monthly or annual basis and are non-transferable.</p>
        <h4>Cancellation and Refund</h4>
        <p> Subscribers may cancel their subscription at any time. Please refer to our refund policy for details on eligibility for refunds upon cancellation.
         </p>
        <h4>User Account</h4>
        <p> Users are responsible for maintaining the confidentiality of their account and password and for restricting access to their computer, and they agree to accept responsibility for all activities that occur under their account or password.
        </p>
        <h4>Content</h4>
        <p>The content displayed on the website is the intellectual property of healthmatrix. You may not reuse, republish, or reprint such content without our written consent.
        </p>
        <h4>Privacy</h4>
        <p> Your use of healthmatrix is also governed by our Privacy Policy. Please review our Privacy Policy, which also governs the site and informs users of our data collection practices.
        </p>
        <h4>Limitation of Liability</h4>
        <p> healthmatrix’s liability is limited to the extent permitted by law, and we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
         </p>
        <h4>Governing Law</h4>
        <p>These terms and conditions are governed by and construed in accordance with the laws of [Jurisdiction] and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
        </p>
        <h4>Changes to Terms and Conditions</h4>
        <p> We reserve the right to amend these terms and conditions at any time. When we do, we will revise the updated date at the bottom of this page.
        </p>
        <h4>Contact Us</h4>
        <p> If you have any questions about these Terms and Conditions, please contact us at support@healthmatrix.co.in.
        </p>
    </div>
    </div>
    </Section>
  </div>
  )
}

export default Terms
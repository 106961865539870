import React from 'react'

import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';

const Refund = () => {
    pageTitle('Refund-Policy | ');
  return (
    <div>
        <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to <br />ProHealth Medical & Healthcare Center"
        subTitle="Your Partner in Health and Wellness"
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <div className='container'>
        <div className='col-md-12'>

        <h4 className='text-center'>Refund Policy</h4>
       <p>Thank you for choosing HealthMatrix, a service provided by Metagates Innovation Pvt. Ltd. We strive to provide exceptional service to all our customers. However, we understand that there may be situations where you may need to request a refund. Please read our refund policy carefully to understand your rights and responsibilities regarding refunds for OPD packages and related services..</p>
       <h4>Refund Eligibility</h4>
      
        <ul>
            <li>1.	Cancellation within Cooling-off Period: If you decide to cancel your OPD package within the cooling-off period, which is typically within [insert number] days from the date of purchase, you may be eligible for a full refund.</li>
            <li>2.	Service Unavailability: If we are unable to provide the promised services due to unforeseen circumstances or technical issues, you may be eligible for a full or partial refund, depending on the situation.
            </li>
           
        </ul>
    
       <h4> How to Request a Refund</h4>
       <p>To request a refund, please contact our customer support team at [insert contact details]. Provide your order details and the reason for the refund request. Our team will review your request and process the refund if you are eligible.
       </p>
    
       <h4> Refund Process</h4>
       <ul>
            <li>1.	Approval: Once your refund request is received, our team will review it to determine if you are eligible for a refund.</li>
            <li>2.	Processing Time: Refunds will be processed within [insert number] business days after approval. Please note that it may take additional time for the refund to reflect in your account, depending on your bank or payment method.
            </li>
           
        </ul>
        <h4>Non-Refundable Items</h4>
      
      <ul>
          <li>1.	Used Services: Services that have been used or partially used are not eligible for refunds.</li>
          <li>2.	Third-party Charges: Any charges incurred through third-party services or platforms are non-refundable.
          </li>
         
      </ul>
      
       <h4>Contact Us</h4>
       <p>If you have any questions or concerns about our refund policy, please feel free to contact our customer support team at [insert contact details]. We are here to assist you and ensure your satisfaction with our services.</p>
       <h4> Policy Updates</h4>
       <p>We reserve the right to update or modify our refund policy at any time without prior notice. Any changes to the policy will be effective immediately upon posting on our website.
        </p>
   
      </div>
      </div>
      </Section>
      </div>


  )
}

export default Refund;
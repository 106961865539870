import React from 'react';

import Text from '../../components/text';
import Spacing from '../Spacing';


export default function TextSection({ sectionTitle, data }) {

  return (
    <div className="container">
    
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-6">
        <ol className="breadcrumb text-capitalize">
        <li className="breadcrumb-item">
        <h4> Need More? </h4>
        </li>
     
      </ol>
      <div className="cs_height_18" />
      <h1 className="cs_fs_72 mb-0">{sectionTitle}</h1>
          
        </div>
        <div className="col-xl-6 ">
        <div className="container">
    

      <div className="row cs_gap_y_50">
        {data?.map((item, index) => (
          <div className="col-xl-12" key={index}>
            <Text {...item} />
          </div>
        ))}
      </div>
    </div>
        </div>
       </div>
      </div>
    
    
  );
}

import React from 'react'

import Section from '../Section'
import BrandsSectionStyle2 from '../Section/BrandsSection/BrandsSectionStyle2';
import Banner from '../Section/BannerSection'
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4'
import BrandsSection from '../Section/BrandsSection'
import TestimonialSection from '../Section/TestimonialSection'
import AppointmentSection from '../Section/AppointmentSection'
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2'

import Breadcrumb from '../Breadcrumb'
import BannerSectionStyle11 from '../Section/BannerSection/BannerSectionStyle11'
import TextSection from '../TextSection'
import { pageTitle } from '../../helpers/PageTitle';

const featureListData = [
  {
    title: 'Discount on Lab Test',
    subTitle:
      'Get access to all radiology and pathology test, and enjoy the heavy discounted rates.',
    iconUrl: 'images/icons/lab.svg',
  },
  {
    title: 'Discount on Medicines',
    subTitle:
      'We made expensive medicines, affordable and accessible. Just think of the cure not the cost.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Free Tele Consultation',
    subTitle:
      'Get free tele consultation from the experienced and reputed doctors. Never worry about the fees.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Interest free medical loans',
    subTitle:
      'Ran out of budget! Need more help? Well, we got you covered. Get interest free medical loan of up to 1 lac for 3 months instantly.',
    iconUrl: 'images/icons/facilities.svg',
  },
];
const brandData = [
  { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_5.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_6.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_7.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_8.png', imgAlt: 'Brand' },
];

const faqData = [
  {
    title: 'What is healthmatrix.co.in?',
    content:
      ' healthmatrix.co.in is a comprehensive healthcare platform offering subscription-based services for OPD, telemedicine consultations, doctor consultancy, diagnosis bookings, and hospital recommendations.',

  },
  {
    title: 'How does the subscription service work?',
    content:
      'Our subscription service allows users to access a range of healthcare services for a fixed monthly fee. Users can subscribe to the service and enjoy benefits such as unlimited OPD consultations, telemedicine appointments, and more.',
  },
  {
    title: 'What are the benefits of subscribing?',
    content:
      'Subscribers enjoy convenient access to healthcare services from the comfort of their homes, cost savings compared to traditional healthcare visits, priority scheduling for appointments, and personalized healthcare recommendations.',
  },
  {
    title: 'How do I book an OPD consultation?',
    content:
      ' Booking an OPD consultation is simple. Just log in to your account, choose your preferred doctor, select a suitable time slot, and confirm your appointment.',
  },
  {
    title: 'Can I choose my preferred doctor for an OPD consultation?',
    content:
      ' Yes, you can browse through our list of experienced doctors, view their profiles, and select the one that best suits your needs.',
  },
];
const TextData = [
  {
    title: 'Family Health Plan',
    subTitle:
      'Specialized care for seniors. Benefit from tailored assessments, fall prevention, and support for your golden years.',
    price: '$249',
    period: '/month',
    featureList: [
      'Up to 20% discount on doctor’s Consultation',
      'Up to 20% discount on pathology and radiology test',
      'Up to 20% discount on medicines',
      '2 Free Tele-consultation',
      '3 month interest free medical loan up to 1 lac',
      'Get annual benefits up to ₹5000/employee',
      'Get a professional expert for assistance',
      'Access to claim assistance',
      '24X7 customer support',
    ],
    btnText: 'Get Started',
    btnUrl: '/contact',
    popular: false,
  },
];
const Opd2  = () => {
  pageTitle ('Opd-package | ');
  return (
    <div>
      <Section topMd={100} bottomMd={0} bottomLg={0}>
      
       
        <Breadcrumb sectionTitle="Your Smart Choice
               Our OPD Plan"
               btnText="Know more"
               btnUrl="/"/>
           
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <BannerSectionStyle11
          bgUrl="/images/doctors/banner_bg.svg"
          imgUrl="images/home_1/corporate.png"
          title=""
          subTitle=""
        />
      </Section>
      <Section>
        <Banner

          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="We allow ease to corporates to think over their employees health"
          subTitle="Contact us and explore all the benefits! Give a smile to your employee and his health."
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="What we are offering?"
          imgUrl="images/about/offering.png"
          data={featureListData}
        />
      </Section>
      <Section topMd={100} bottomMd={0} bottomLg={0}>
      
       
      <TextSection sectionTitle="We have everything you need to get started"
             data={TextData}
            />
         
    </Section>
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Get a Quote"
          sectionTitleUp="Interested"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
          sectionTitle="Frequently Asked <br />Questions"
          data={faqData}
          faqImgUrl="/images/home_4/faq_img.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
        />
      </Section>
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSectionStyle2 data={brandData} />
      </Section>



    </div>
  )
}

export default Opd2
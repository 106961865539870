import React from 'react';
import Pricing from '../../Pricing';
import Slider from 'react-slick';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import Button from '../../Button';

export default function PricingSection({ sectionTitle, data, sectionTitleUp, }) {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'cs_slider_prev cs_center' +
        (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <img src="images/icons/left_arrow_blue.svg" alt="Icon" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'cs_slider_next cs_center' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <img src="images/icons/right_arrow_blue.svg" alt="Icon" />
    </div>
  );
  const settings = {
    dots: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="container">
      <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
      <Spacing md="72" lg="50" />
      <Slider {...settings} className="cs_gap_20 cs_cs_slider_navigation_3">
        
        {data?.map((item, index) => (
          <div key={index}>
            <Pricing {...item} />
          </div>
        ))}
      </Slider>
    
      {/* <div className="row cs_gap_y_50">
        {data?.map((item, index) => (
          <div className="col-xl-4" key={index}>
            <Pricing {...item} />
          </div>
        ))}
      </div>*/}
    </div>
  );
}

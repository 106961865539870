import React from 'react';
import Button from '../Button';
import { Icon } from '@iconify/react';

export default function Pricing({
  title,
  subTitle,
  price,
  period,
  priceTag,
  featureList,
  featureList2,
  featureList3,
  featureTitle,
  featureTitle2,
  featureTitle3,
  featureTitle4,
  btnText,
  btnUrl,
  Premium,
  popular,
}) {
  return (
    <div className="cs_pricing_card cs_style_1 cs_radius_20 overflow-hidden">
      <div className="cs_pricing_card_head cs_accent_bg cs_white_color">
        <h3 className="cs_white_color cs_fs_24 cs_semibold">
          <span className="cs_accent_bg">{title}</span>
          {Premium && (
            <span className="cs_heading_color cs_normal">Premium</span>
          )}
              {popular && (
            <span className="cs_heading_color cs_normal">Popular</span>
          )}
        </h3>
   
        {subTitle && <p>{subTitle}</p>}
        {priceTag && <p>{priceTag}</p>}
        <h2 className="cs_white_color mb-0 cs_fs_72 cs_semibold">
          {price}
          <span className="cs_fs_24">{period}</span>
        </h2>
      </div>
      <div className="cs_pricing_card_body">
        <ol className="cs_pricing_card_feature cs_fs_20 cs_heading_color">
        <li>
              <i>
                <Icon icon="fa6-solid:circle-check" />
              </i>
        {featureTitle}
        </li>
          {featureList?.map((item, index) => (
            <li key={index}>
             
              {item}
            </li>
          ))}
          <li>
              <i>
                <Icon icon="fa6-solid:circle-check" />
              </i>
              {featureTitle2}
        </li>
        {featureList2?.map((item, index) => (
            <li key={index}>
             
              {item}
              </li>
          ))}
      
            <li>
              <i>
                <Icon icon="fa6-solid:circle-check" />
              </i>
              {featureTitle3}
              </li>
              {featureList3?.map((item, index) => (
            <li key={index}>
             
              {item}
              </li>
          ))}
             <li>
              <i>
                <Icon icon="fa6-solid:circle-check" />
              </i>
              {featureTitle4}
              </li>
     
        </ol>
        <div className="cs_pricing_card_btn">
          <Button btnUrl={btnUrl} btnText={btnText} variant="w-100" />
        </div>
      </div>
    </div>
  );
}

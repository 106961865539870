import React from 'react'
import { pageTitle } from '../../helpers/PageTitle';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import Section from '../Section';
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4';


  const faqData = [
    {
      title: 'What is healthmatrix.co.in?',
      content:
        ' healthmatrix.co.in is a comprehensive healthcare platform offering subscription-based services for OPD, telemedicine consultations, doctor consultancy, diagnosis bookings, and hospital recommendations.',
        
    },
    {
      title: 'How does the subscription service work?',
      content:
        'Our subscription service allows users to access a range of healthcare services for a fixed monthly fee. Users can subscribe to the service and enjoy benefits such as unlimited OPD consultations, telemedicine appointments, and more.',
    },
    {
      title: 'What are the benefits of subscribing?',
      content:
        'Subscribers enjoy convenient access to healthcare services from the comfort of their homes, cost savings compared to traditional healthcare visits, priority scheduling for appointments, and personalized healthcare recommendations.',
    },
    {
      title: 'How do I book an OPD consultation?',
      content:
        ' Booking an OPD consultation is simple. Just log in to your account, choose your preferred doctor, select a suitable time slot, and confirm your appointment.',
    },
    {
      title: 'Can I choose my preferred doctor for an OPD consultation?',
      content:
        ' Yes, you can browse through our list of experienced doctors, view their profiles, and select the one that best suits your needs.',
    },
    {
      title: 'What is telemedicine, and how does it work?',
      content:
        ' Telemedicine allows patients to consult with healthcare professionals remotely via video or phone calls. Users can schedule telemedicine appointments through our platform and connect with doctors from anywhere.',
    }, 
    {
      title: 'Is telemedicine secure and confidential?',
      content:
        '  Absolutely. We prioritize the security and confidentiality of patient information. Our telemedicine platform complies with all relevant data protection regulations to ensure privacy and security.',
    },
    {
      title: 'What types of healthcare professionals are available for consultancy?',
      content:
        'Our platform offers consultations with a diverse range of healthcare professionals, including general physicians, specialists, therapists, and more.',
    },
    {
      title: 'Can I ask multiple questions during a doctor consultancy session?',
      content:
        ' Yes, you can discuss multiple concerns during your consultancy session. Our doctors are here to address all your healthcare needs comprehensively.',
    },
    {
      title: 'How do I book a diagnostic test?',
      content:
        ' Booking a diagnostic test is easy. Simply log in to your account, select the test you need, choose a certified lab or diagnostic center near you, and schedule your appointment.',
    },
    {
      title: 'How soon can I expect my test results?',
      content:
        ' The turnaround time for test results varies depending on the type of test. However, we strive to deliver results as quickly as possible and keep you informed throughout the process.',
    },
    {
      title: 'Can you recommend hospitals and healthcare facilities?',
      content:
        'Yes, we provide recommendations for hospitals and healthcare facilities based on your location, medical needs, and preferences. Our goal is to help you find the best possible care options.',
    },
    {
      title: 'How can I manage my account?',
      content:
        ' You can manage your account settings, appointments, subscriptions, and payment details through our user-friendly dashboard.',
    },
    {
      title: 'What payment methods do you accept?',
      content:
        ' We accept payments through various secure methods, including credit/debit cards, net banking, and digital wallets.',
    },
    {
      title: 'What should I do if I encounter technical issues on the website?',
      content:
        ' If you experience any technical difficulties or have questions about using our platform, please reach out to our customer support team. We are here to assist you 24/7.',
    },
    {
      title: 'How do you protect my personal and medical information?',
      content:
        ' We take data privacy and security seriously. Our platform employs robust encryption protocols and adheres to strict privacy regulations to safeguard your personal and medical information.',
    },
  ]
    const Faq = () => {
        pageTitle('Faq-Questions | ');
  return (
    <div>
    <BannerSectionStyle3
    bgUrl="/images/about/banner_bg.svg"
    imgUrl="/images/about/ladki_img.png"
    title="Frequently Asked <br />Questions"
    subTitle="What People Usually Asked"
  />
 
  <Section
      
        
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
        
          data={faqData}
          faqImgUrl="/images/home_4/faq_img.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
        />
      </Section>
      
        </div>
  )
}

export default Faq

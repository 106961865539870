import React from 'react';
import { Outlet } from 'react-router-dom';
// import Footer from '../Footer';
import Header from '../Header';
import FooterStyle2 from '../Footer/FooterStyle2';

export default function Layout() {
  return (
    <>
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" />
      <Outlet />
      {/* <Footer /> */} <FooterStyle2 />
    </>
  );
}
